import React, { useState, useEffect } from 'react';
import api from '../api';
import EditPartForm from './EditPartForm';

function PartSpreadsheet() {
  const [parts, setParts] = useState([]);
  const [editingPart, setEditingPart] = useState(null);

  useEffect(() => {
    const fetchParts = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await api.get('/api/admin/car_parts', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setParts(response.data);
      } catch (error) {
        console.error('Error fetching parts:', error);
      }
    };

    fetchParts();

    // Listen for the custom event
    window.addEventListener('partAdded', fetchParts);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('partAdded', fetchParts);
  }, []);
  const handleEditClick = (part) => {
    setEditingPart(part);
  };

  const handleDeleteClick = async (id) => {
    try {
      const token = localStorage.getItem("accessToken");
      await api.delete(`/api/car_parts/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Remove the deleted part from the state
      setParts(parts.filter((part) => part.id !== id));
    } catch (error) {
      console.error("Error deleting part:", error);
    }
  };

  const handleEditFormSubmit = async (updatedPart) => {
    try {
      // Update the part in the state
      setParts(
        parts.map((part) =>
          part.id === updatedPart.id ? updatedPart : part,
        ),
      );
      setEditingPart(null); // Close the edit form
    } catch (error) {
      console.error("Error updating part:", error);
    }
  };

  return (
    <div className="table-responsive">
      {/* Make that shit responsive */}
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Actions</th>
            <th>ID</th>
            <th>Name</th>
            <th>Picture</th>
            <th>Part Number</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Sold</th>
          </tr>
        </thead>
        <tbody>
          {parts.map((part) => (
            <tr key={part.id}>
              <td>
                <button
                  className="btn btn-sm btn-primary me-2"
                  onClick={() => handleEditClick(part)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => handleDeleteClick(part.id)}
                >
                  Delete
                </button>
              </td>
              <td>{part.id}</td>
              <td>{part.name}</td>
              <td>{part.images && part.images.length > 0 ? 'Yes' : 'No'}</td>
              <td>{part.part_number}</td>
              <td>${part.price}</td>
              <td>{part.quantity}</td>
              <td>{part.sold ? "Yes" : "No"}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingPart && (
        <EditPartForm
          part={editingPart}
          onSubmit={handleEditFormSubmit}
          onCancel={() => setEditingPart(null)}
        />
      )}
    </div>
  );
}


export default PartSpreadsheet;
