// AdminPortal.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { jwtDecode } from 'jwt-decode';

import UserManager from './UserManager'
import ContentManager from './ContentManager';
import InventoryManager from './InventoryManager';

const AdminMode = {
  INVENTORY: 'inventory',
  USERS: 'users',
  CONTENT: 'content',
};

function AdminPortal() {
  const navigate = useNavigate();
  const [mode, setMode] = useState(() => {
    const storedMode = localStorage.getItem('adminMode');
    return storedMode || AdminMode.INVENTORY;
  }); const token = localStorage.getItem('accessToken');

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      // Check if the token is expired
      if (decodedToken.exp < Date.now() / 1000) {
        navigate('/login');
      }
    } catch (error) {
      console.error('Invalid token:', error);
      navigate('/login');
    }
  }, [navigate, token]);

  const handleToggleView = (newMode) => {
    setMode(newMode);
  };

  useEffect(() => {
    localStorage.setItem('adminMode', mode);
  }, [mode]);


  return (
    <div className="container mt-4">
      <h2>Admin Portal</h2>

      <div className="mb-3">
        {/* Buttons to switch between modes */}
        <button
          className={`btn ${mode === AdminMode.INVENTORY ? 'btn-primary' : 'btn-secondary'} me-2`}
          onClick={() => handleToggleView(AdminMode.INVENTORY)}
        >
          Inventory Management
        </button>
        <button
          className={`btn ${mode === AdminMode.USERS ? 'btn-primary' : 'btn-secondary'} me-2`}
          onClick={() => handleToggleView(AdminMode.USERS)}
        >
          User Management
        </button>
        <button
          className={`btn ${mode === AdminMode.CONTENT ? 'btn-primary' : 'btn-secondary'}`}
          onClick={() => handleToggleView(AdminMode.CONTENT)}
        >
          Content Management {/* New button for content editing */}
        </button>
      </div>

      {(() => {
        switch (mode) {
          case AdminMode.INVENTORY:
            return <InventoryManager />;
          case AdminMode.USERS:
            return <UserManager />;
          case AdminMode.CONTENT:
            return <ContentManager />;
          default:
            return null;
        }
      })()}
    </div>
  );
}

export default AdminPortal;
