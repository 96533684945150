// src/pages/ContentManager.jsx
import React, { useState, useEffect, useRef } from 'react';
import api from '../api';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css'; // snow or bubble here
import MarkdownShortcuts from 'quill-markdown-shortcuts'

const ContentManager = () => {
  const pageNames = ["Choose a page...", "about", "policy", "legal", "contact"];
  const [pageEditing, setPageEditing] = useState(pageNames[0]);
  const [pageContent, setPageContent] = useState("");
  const { quill, quillRef, Quill } = useQuill({ modules: { markdownShortcuts: {} } });

  if (Quill && !quill) {
    console.log("adding markdown plugin to quill");
    Quill.register('modules/markdownShortcuts', MarkdownShortcuts);
  }
  function setEditorText(content) {
    quill.deleteText(0, quill.getLength(), 'silent');
    quill.clipboard.dangerouslyPasteHTML(content, 'api');
  }

  useEffect(() => {
    if (pageEditing === pageNames[0]) {
      return
    }

    const token = localStorage.getItem('accessToken');

    const fetchContent = async () => {
      try {
        const response = await api.get(`/api/content/${pageEditing}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.status === 200) {
          setEditorText(response.data.content)
        }
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchContent();
  }, [pageEditing]);

  const handlePageEditingChanged = (event) => {
    setPageEditing(event.target.value);
  };

  const handleSaveContent = async () => {
    console.log(`now sending put request to update page '${pageEditing}' with content '${pageContent}'`)
    try {
      const token = localStorage.getItem('accessToken');

      await api.put(`/api/content/${pageEditing}`,
        { "content": pageContent },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      alert("Content saved successfully.")
      console.log("Content saved successfully.");
    } catch (error) {
      alert("Error saving content.")
      console.error("Error saving content:", error);
    }
  };

  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        const innerHTML = quillRef.current.firstChild.innerHTML
        console.log(innerHTML);
        setPageContent(innerHTML);
      });
    }
  }, [quill, quillRef]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h3>Page</h3>
              <select
                className="form-select px-4"
                value={pageEditing}
                onChange={handlePageEditingChanged}
              >
                {pageNames.map((page) => (
                  <option key={page} value={page}>
                    {page}
                  </option>
                ))}
              </select>
              <button className="btn btn-primary" onClick={handleSaveContent}>
                Save Changes
              </button>
            </div>
            <div className="row mt-3"> {/* Move the editor below the controls */}
              <div className="col-md-12">
                <div className="card-body">
                  <div>
                    <h3>Edit Content</h3>
                  </div>
                  <div
                    ref={quillRef}
                    style={{ width: "100%", height: "calc(100vh - 250px)" }}
                    value={pageContent}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentManager;
