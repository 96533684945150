import React, { useState, useEffect } from 'react';
import api from '../api'
import PartCard from '../components/PartCard';

function PartCatalog() {
  const [parts, setParts] = useState([]);

  useEffect(() => {
    const fetchParts = async () => {
      try {
        const response = await api.get('/api/car_parts'); // Replace with your API endpoint
        setParts(response.data);
      } catch (error) {
        console.error('Error fetching parts:', error);
      }
    };

    fetchParts();
  }, []);

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Browse Our Inventory</h2>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {parts.map((part) => (
          <div className="col" key={part.id}>
            <PartCard part={part} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PartCatalog;
