// LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const encodedCredentials = btoa(`${username}:${password}`);  // Encode that shit
      const response = await api.post('/api/login', null, {  // Send null for the request body
        headers: {
          'Authorization': `Basic ${encodedCredentials}`  // Include that shit in the header
        }
      });
      localStorage.setItem('accessToken', response.data.access_token);
      navigate('/admin');
      window.location.reload(); // Refresh the page

    } catch (error) {
      console.error('Login failed:', error);
      // Show an error message to the user
    }
  };

  return (
    <div className="container mt-4">
      <h2>Employee Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="username" className="form-label">Username</label>
          <input
            type="text"
            className="form-control"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoComplete="username email"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete='current-password'
          />
        </div>
        <button type="submit" className="btn btn-primary">Login</button>
      </form>
    </div>
  );
}

export default LoginPage;
