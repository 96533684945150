import api from '../api'

const urlCache = {};

export const uploadImage = async (file) => {
  try {
    const formData = new FormData();
    formData.append('image', file);

    const response = await api.post('/api/images/', {
      body: formData,
    });
    const data = response.json();
    return data.url; // Return the image key from the server
  } catch (error) {
    console.error('Error uploading image:', error);
  }
  return null;
};

export const getImageUrl = async (key) => {
  console.log("image url cache:", urlCache);
  if (urlCache[key]) {
    return urlCache[key];
  }

  try {
    const response = await api.get(`/api/images/${key}`);
    urlCache[key] = response.data.url;

    return response.data.url;
  }
  catch (error) {
    console.error('Error retrieving image url from server:', error);
    // return { 'url': 'https://placehold.co/400' };
  }
}

