import PartForm from '../components/AddPartForm';
import PartSpreadsheet from '../components/PartSpreadsheet';

const InventoryManager = () => {
    return (
        <>
            <PartForm />
            <PartSpreadsheet />
        </>
    );
};

export default InventoryManager;
