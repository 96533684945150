import React, { useState, useRef, useEffect } from 'react';

const Camera = () => {
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    async function getCameraStream() {
      try {
        const constraints = { video: { facingMode: 'environment' } }; // Use back camera on mobile
        const newStream = await navigator.mediaDevices.getUserMedia(constraints);
        setStream(newStream);
        if (videoRef.current) {
          videoRef.current.srcObject = newStream;
        }
      } catch (error) {
        console.error('Error accessing camera:', error);
        // alert("Camera access was denied. Please enable camera access in your browser settings.")
      }
    }

    getCameraStream();

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const takePicture = () => {
    if (videoRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      const video = videoRef.current;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

      // Get the image data as a data URL
      const imageDataUrl = canvas.toDataURL('image/png'); // You can change the format if needed
      // Now you can use imageDataUrl, for example, send it to your server
      console.log("Image data URL:", imageDataUrl)
      // Or create a Blob for direct upload
      canvas.toBlob((blob) => {
        if (blob) {
          const imageFile = new File([blob], 'captured_image.png', { type: 'image/png' });
          // Now you have a File object that you can use in a FormData object for uploading.
          console.log("Image file:", imageFile)

          //Example upload
          const formData = new FormData()
          formData.append('image', imageFile)

          fetch('/upload', {
            method: 'POST',
            body: formData
          })
            .then(res => res.json())
            .then(data => console.log(data))
            .catch(err => console.error(err))
        }
      });
    }
  };

  return (
    <div>
      <video ref={videoRef} autoPlay playsInline muted></video>
      <button onClick={takePicture}>Take Picture</button>
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas> {/* Hidden canvas */}
    </div>
  );
}

export default Camera;
