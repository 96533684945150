import { useState, useEffect } from "react";
import { getImageUrl } from "./ImageUtils"

const PartCard = ({ part }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (part.images && part.images.length > 0) {
        const url = await getImageUrl(part.images[0])
        setImageUrl(url);
      } else {
        setImageUrl('https://placehold.co/400');
      }
    }

    fetchImageUrl();
  }, [part.images]);

  return (
    <div className="card">
      <img src={imageUrl} className="card-img-top" alt={part.name} />
      <div className="card-body">
        <h5 className="card-title">{part.name}</h5>
        <p className="card-text">{part.description}</p>
        <p className="card-text">Part Number: {part.part_number}</p>
        <p className="card-text">Price: ${part.price}</p>
      </div>
    </div>
  );
}

export default PartCard;
