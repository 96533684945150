import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../api';

function Header() {
  const [portalText, setPortalText] = useState('Staff Login')
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Add state for login status
  const [loggedInAsString, setUsername] = useState(null); // Add state for username

  useEffect(() => {
    const checkLoginStatus = async () => { // Function to check login status
      const token = localStorage.getItem("accessToken");
      if (token) { // Only check if token exists
        try {
          const response = await api.get('/api/login', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          setUsername(`Logged in as: ${response.data.username}`);
          setIsLoggedIn(true);
          setPortalText('Staff Portal');
        } catch (error) {
          setIsLoggedIn(false);
          setUsername(null);
          setPortalText('Staff Login');
        }
      } else {
        setIsLoggedIn(false);
        setUsername(null);
        setPortalText('Staff Login');
      }
    };

    // Event listener for storage changes
    const handleStorageChange = () => {
      checkLoginStatus();
    };

    // Attach the event listener
    window.addEventListener('storage', handleStorageChange);

    // Initial check on component mount
    checkLoginStatus();

    // Clean up the listener on unmount
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []); // Empty dependency array ensures this runs only once on mount

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    window.location.reload(); // Refresh the page
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="/#">
          {/* <img src="/path/to/your/logo.png" alt="Part Junkies Logo" width="30" height="24" className="d-inline-block align-text-top me-2" /> */}
          Parts Junkies
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/admin">{portalText}</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">Our Company</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">Contact Us</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/policy">Seller Policy</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/legal">Terms of Service</Link>
            </li>
            {isLoggedIn && (
              <>
                <li className="nav-item">
                  <span className="nav-link">
                    {loggedInAsString}
                  </span>
                </li>
                <li className="nav-item">
                  <button className="btn btn-link nav-link" onClick={handleLogout}>
                    Logout
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
