import React, { useState, useEffect } from 'react';
import api from '../api'
import { NumericFormat } from 'react-number-format';
import { getImageUrl } from './ImageUtils'

const EditPartForm = ({ part, onCancel }) => {
  const [name, setName] = useState(part.name);
  const [description, setDescription] = useState(part.description);
  const [part_number, setPartNumber] = useState(part.part_number);
  const [price, setPrice] = useState(part.price);
  const [quantity, setQuantity] = useState(part.quantity);
  const [online_listing_url, setOnlineListingUrl] = useState(
    part.online_listing_url,
  );
  const [image, setImage] = useState(null); // form image data
  const [imageUrl, setImageUrl] = useState(null); // image preview

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (part.images && part.images.length > 0) {
        const url = await getImageUrl(part.images[0])
        setImageUrl(url);
      } else {
        setImageUrl('https://placehold.co/400');
      }
    }

    fetchImageUrl();
  }, [part.images]);


  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setImage(newImage);

    if (newImage) {
      const tempUrl = URL.createObjectURL(newImage);
      setImageUrl(tempUrl);

      // Clean up the old URL if there was one
      if (imageUrl && typeof imageUrl === 'string' && imageUrl.startsWith('blob:')) {
        URL.revokeObjectURL(imageUrl);
      }
    } else {
      // If no image is selected, reset the image URL
      setImageUrl(null);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const updated_part_data = {
      'name': name,
      'description': description,
      'part_number': part_number,
      'quantity': quantity,
      'price': price.toFixed(2),
      'online_listing_url': online_listing_url,
    }
    const formDataToSend = new FormData();

    if (image) {
      formDataToSend.append('image', image);
    }
    formDataToSend.append('car_part_raw', JSON.stringify(updated_part_data));

    try {
      const token = localStorage.getItem("accessToken");
      const response = await api.put(`/api/car_parts/${part.id}`, formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': formDataToSend.get('Content-Type')
        },
      });
      window.dispatchEvent(new Event('partAdded'));
      alert('Successful edit.', response.data);
      console.log("successfully edited part data.");
    } catch (error) {
      alert('Error with editing part.');
      console.error("Error updating part:", error);
    }
  };


  return (
    <div className="modal show d-block" tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Part</h5>
            <button type="button" className="btn-close" onClick={onCancel}></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="edit-image" className="form-label">
                </label>
                {imageUrl ? ( // Check if cachedImageUrl is not null
                  <img html-for="edit-image" src={imageUrl} alt={part.name} className="img-fluid" />
                ) : (
                  <p>No image available</p> // Display a message if no image is available
                )}
                <input
                  type="file"
                  className="form-control"
                  id="edit-image"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="edit-name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="edit-name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="edit-description" className="form-label">
                  Description
                </label>
                <textarea
                  className="form-control"
                  id="edit-description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="edit-partNumber" className="form-label">
                  Part Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="edit-partNumber"
                  value={part_number}
                  onChange={(e) => setPartNumber(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="edit-price" className="form-label">
                  Price
                </label>
                <NumericFormat
                  displayType='input'
                  thousandSeparator={true}
                  prefix={'$'}
                  className="form-control form-control-lg"
                  id="price"
                  name="price"
                  value={price}
                  valueIsNumericString={false}
                  onValueChange={(e) => setPrice(e.floatValue)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="edit-quantity" className="form-label">
                  Quantity
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="edit-quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="edit-onlineListingUrl" className="form-label">
                  Online Listing URL
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="edit-onlineListingUrl"
                  value={online_listing_url}
                  onChange={(e) => setOnlineListingUrl(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Save Changes
              </button>
              <button
                type="button"
                className="btn btn-secondary ms-2"
                onClick={onCancel}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditPartForm;
