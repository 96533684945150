import React, { useState, useEffect } from 'react';
import api from '../api';

const StaticPageTemplate = ({ pageName }) => {
  const [pageContent, setPageContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response = await api.get(`/api/content/${pageName}`);
        if (response.status === 200) {
          setPageContent(response.data.content);
        } else {
          setError(`Failed to fetch content for ${pageName}. Status: ${response.status}`);
        }
      } catch (err) {
        setError(`Error fetching content: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchPageContent();
  }, [pageName]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <div dangerouslySetInnerHTML={{ __html: pageContent }} />
    </div>
  );
};

// Example usage in your page components:
const AboutPage = () => {
  return <StaticPageTemplate pageName="about" />;
};

const LegalPage = () => {
  return <StaticPageTemplate pageName="legal" />;
};

const PolicyPage = () => {
  return <StaticPageTemplate pageName="policy" />;
};

const ContactPage = () => {
  return <StaticPageTemplate pageName="contact" />;
};

export { AboutPage, LegalPage, PolicyPage, ContactPage, StaticPageTemplate };
