import axios from 'axios';

const apiURL = process.env.NODE_ENV === 'development'
    ? 'http://127.0.0.1:8000'
    : 'https://pj-website-c49a5c401e2c.herokuapp.com/';

// const originURL = window.location.origin // development "http://localhost:3000" or "http://pj.gob.lan", production "https://pj-client-3ca980cf9e6f.herokuapp.com/"

const api = axios.create({
    baseURL: apiURL,
});

export default api;
