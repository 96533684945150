import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Header from "./components/Header"
import PartCatalog from "./pages/PartCatalog"
import LoginPage from './pages/LoginPage';
import AdminPortal from './pages/AdminPortal';

import { AboutPage, LegalPage, PolicyPage, ContactPage } from "./pages/StaticLayout";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Header />

        <Routes>
          <Route path="/" element={<PartCatalog />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/admin" element={<AdminPortal />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/policy" element={<PolicyPage />} />
          <Route path="/legal" element={<LegalPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
