import React, { useState } from 'react';
import api from "../api";
import { NumericFormat } from 'react-number-format';
import Camera from './Camera';

const PartForm = () => {
  const [formData, setFormData] = useState({
    image: null,
    name: '',
    description: '',
    price: 0.00,
    part_number: '',
    online_listing_url: '',
    quantity: 1
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log('handleChange:', name, value);
    setFormData({ ...formData, [name]: value });
  };

  const handlePriceChange = (values, sourceinfo) => {
    console.log(values, sourceinfo);
    // const { value } = event.target;
    setFormData({ ...formData, price: values.floatValue })
    console.log('handlePriceChange:', values);
  }

  const handleImageChange = (event) => {
    setFormData({ ...formData, image: event.target.files[0] });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('handleSubmit formData.price:', formData.price);

    const carPartData = {
      'name': formData.name,
      'description': formData.description,
      'part_number': formData.part_number,
      'price': formData.price.toFixed(2),
      'online_listing_url': formData.online_listing_url,
    }

    const formDataToSend = new FormData();

    formDataToSend.append('car_part_raw', JSON.stringify(carPartData));
    if (formData.image) {
      formDataToSend.append('image', formData.image);
    }

    try {
      const token = localStorage.getItem('accessToken');

      const response = await api.post('/api/car_parts', formDataToSend, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Encoding': ``,
          'Content-Type': formDataToSend.get('Content-Type')
        }
      });
      window.dispatchEvent(new Event('partAdded'));
      console.log("successfully added part.", response.data);
      alert("Successfully added part to the inventory.")
      setFormData({ // Clear that form like a boss
        image: null,
        name: '',
        description: '',
        price: 0.00,
        part_number: '',
        online_listing_url: '',
        quantity: 1
      });
    } catch (error) {
      console.error("Error adding part:", error);
      alert("Failed to add part. Please check the console for details.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="container">
      <h3>Add a Part</h3>
      <div className="row">
        {/* <Camera /> */}

        <div className="col-12 mb-3">
          <label htmlFor="image" className="form-label">
            Image
          </label>
          <input
            type="file"
            className="form-control form-control-lg"
            id="image"
            name="image"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>


        <div className="col-12 mb-3">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control form-control-lg"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-12 mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <textarea
            className="form-control form-control-lg"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows="3" // Add more vertical space for textarea
          />
        </div>
        <div className="col-12 mb-3">
          <label htmlFor="part_number" className="form-label">
            Part Number
          </label>
          <input
            type="text"
            className="form-control form-control-lg"
            id="part_number"
            name="part_number"
            value={formData.part_number}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-12 mb-3">
          <label htmlFor="price" className="form-label">
            Price
          </label>
          <NumericFormat
            displayType='input'
            thousandSeparator={true}
            prefix={'$'}
            className="form-control form-control-lg"
            id="price"
            name="price"
            value={formData.price}
            valueIsNumericString={false}
            onValueChange={handlePriceChange}
          />
        </div>
        {/* <div className="col-12 mb-3">
          <label htmlFor="quantity" className="form-label">
            Quantity
          </label>
          <input
            type="number"
            className="form-control form-control-lg"
            id="quantity"
            name="quantity"
            value={formData.quantity}
            onChange={handleChange}
          />
        </div> */}
        <div className="col-12 mb-3">
          <label htmlFor="online_listing_url" className="form-label">
            Online Listing URL
          </label>
          <input
            type="text"
            className="form-control form-control-lg"
            id="online_listing_url"
            name="online_listing_url"
            value={formData.online_listing_url}
            onChange={handleChange}
          />
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary btn-lg w-100">
            Add Part
          </button>
        </div>
      </div>
    </form>
  );
}

export default PartForm;
